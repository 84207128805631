import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"
import styled, { css } from "styled-components"
import { brand, font, mq } from "../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";


const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;
    
    @media only screen and ${mq.maxMd} {
      text-align: center;
      font-size: 42px;
    }

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

}
`;

const Copy = styled.div`
    margin-top: 50px;
    margin-bottom: 20px;
  p,ul {
    color: ${brand.colors.pg_primary_dark};
    font-size: 22px;
  } 
`;

const PrivacyPolicy = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`
const PrivacyPage = () => {


  return(
    <Layout>
    <SEO title="Privacy | Plusgrade" 
          description={'Plusgrade Privacy Policy'}
      />
      <Content whiteBG e2e>
        <PrivacyPolicy>
          <Header>
              <h2>Plusgrade <span className={"color-emphasis"}>Privacy Policy</span></h2>
          </Header>
          <Copy>
          <p><strong>Effective Date:</strong> November 4, 2023</p>
            <p>
            This Privacy Policy explains what data we collect from visitors to the Plusgrade website www.plusgrade.com (including all subdomains, the “Site”) and/or our services and in connection with our events, and sales and marketing activities, how we process and protect personal data and your rights and choices with respect to your personal data in our capacity as a data controller.
            </p>
            
            <p>In this Privacy Policy: </p>
            
            <ul>
            <li>"personal data" includes information defined as “personal data” or “personal information” under applicable privacy laws. Information that is aggregated and/or de-identified and cannot be associated with an identifiable individual or lead to an individual being identified is not considered to be personal data; and </li>
            <li>“data subject” means an identifiable individual to whom personal data directly or indirectly relates.</li>
            </ul>
            <p>Please read this Privacy Policy carefully to understand our practices for collecting, using and disclosing your personal data. If you do not agree with our practices, your choice is not to use our Site or use our services. By accessing or using this Site or our services, where your understanding, acceptance or consent are required under applicable privacy laws, you indicate that you understand, accept, and consent to the practices described in this policy. </p>
            <p>Click on any of the links below to read a summary for that topic. If you want to learn further detailed information, click “more” in that section.</p>
            
            <div className="FAQ-expandable-container">
        {/** Question # 1 */}
              <QuestionSection 
                question = {"What is the purpose of this privacy policy?"}
                answer={
                  <div>
                    <p>
                    Plusgrade provides a software product and services (together, “services”) that enable travel industry companies (i.e., Plusgrade’s “business partners”) to offer their customers (“travelers”) the opportunity to upgrade their travel experience, subject to each business partners' individual discretion and the availability of the offering.</p>
                    <p>
                    Protecting privacy is important to us and to our business partners. This is why we are committed to ensuring the security and confidentiality of the personal data we receive and process from our business partners as part of the services we provide to them. We are also committed to providing a software product that meets industry best practices and regulatory requirements for privacy and data protection.</p>
                    <p>
                    The purpose of this Privacy Policy is to inform Plusgrade’s business partners and travelers about our privacy and data handling practices. This Privacy Policy also applies to data we collect from visitors of the Site and in connection with our events, and sales and marketing activities.</p>
                  </div>
                }

                learnMoreContent={
                  <div>
                    <p>
                      This Privacy Policy explains how Plusgrade processes personal data, how we protect it in the course of our business and your rights.
                    </p>
                    <p>
                      The information contained in this privacy policy includes:
                    </p>
                    <ul>
                      <li>Plusgrade’s contact details, including its Data Protection Officer and representative in the European Union;</li>
                      <li>The purposes for which we process personal data and our legal bases for the processing;</li>
                      <li>The types of personal data we process;</li>
                      <li>The categories of third parties to whom we may disclose the data;</li>
                      <li>Jurisdictions in which we process personal data and safeguards in place for international transfers of personal data;</li>
                      <li>The criteria used to determine the period for which we store personal data; and</li>
                      <li>The privacy rights that apply to travelers from which Plusgrade process personal data on behalf of its business partners, including the right to lodge a complaint with the competent Privacy authority.</li>
                    </ul>
                  </div>
                }
              />
          {/** Question # 2 */}
              <QuestionSection 
                question = {"What personal data does Plusgrade process?"}
                answer={
                  <div>
                    <p>
                    As a third-party service provider to travel industry companies (i.e., Plusgrade’s “business partners”), Plusgrade generally receives personal data that was initially disclosed by travelers to our business partners in connection with a travel booking or purchase, for the purpose of providing the Services to our business partners in accordance with the applicable service contract.</p>

                    <p>Plusgrade may also receive from its business partners information about travelers’ service requests (e.g. flight meal preferences, class of travel). When such information is processed by Plusgrade and is associated to an individual, it is treated as personal data for the purposes of this Privacy Policy.

</p>
<p>We collect from our business partners, prospects and Site visitors information that is entered on our Site or sent to us electronically, for example when business partners purchase our Services or when our Site visitors request more information. We may also collect personal data that you provide to us when you attend one of our events, during phone calls with sales representatives or when you contact customer support.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                        Personal Data About Travelers:
                        <p>
                          We process personal data provided by travelers in connection with a booking or purchase of travel services from travel industry companies (i.e., Plusgrade’s “business partners”), on behalf of our business partners. This typically includes or may include the following categories of personal data:
                        </p>
                        <ul>
                          <li>
                            Contact Information, which may include name and email address
                          </li>
                          <li>
                            Authentication information, which may include passport information, and loyalty program number
                          </li>
                          <li>
                            Trip related preferences and loyalty level information
                          </li>
                          <li>
                            Communications with our business partners, including email communications
                          </li>
                          <li>
                            Location Data, including departure and destination airport or port
                          </li>
                          <li>
                            Online identifiers, including IP address
                          </li>
                        </ul>
                      </li>

                      <li>
                      Personal Data About Business Partners, Prospects, Site Visitors:
                      <p>
                        <strong className="italized">Personal Data That You Provide To Us.</strong> We collect information that you enter on our Site or send to us electronically, for example, when you request information, including a product demo, register for a webinar or other event, or subscribe to emailing lists. While the type of data we collect depends on the nature of the inquiry, this typically includes your name and contact details, company information, and phone number. We may also collect personal data that you provide to us when you attend one of our events (such as your contact details), during phone calls with sales representatives, or when you contact our customer support.
                      </p>
                      <span className="italized">Automatically Collected Data.</span>
                      <p>
                        <strong className="italized">Log Data.</strong> When you visit our Site, our servers record information (“log data”), including information that your browser automatically sends whenever you visit the Site. This log data includes your Internet Protocol (“IP”) address (from which we understand the country you are connecting from at the time you visit the Site), browser type and settings, the date and time of your request.
                      </p>
                      <p>
                        <strong className="italized">Cookies.</strong> When you visit the Site, we and our service providers acting on our behalf will automatically collect information about you through cookies (small text files placed on your device).
                      </p>
                      </li>
                    </ol>
                  </div>

                }
              />
              {/** Question # 3 */}
              <QuestionSection 
                question = {"From where does Plusgrade obtain personal data?"}
                answer={
                  <ol>
                    <li>
                      Personal Data About Travelers:
                      <p>Plusgrade obtains personal data provided by travelers in connection with a booking or purchase of travel services from travel industry companies (i.e., Plusgrade’s “business partners”), for the purpose of enabling travelers to participate in booking upgrades and related services provided by Plusgrade in accordance with the applicable service contract.</p>
                    </li>
                    <li>
                      Personal Data About Business Partners, Prospects, Site Visitors:
                      <p>Plusgrade obtains the personal data that it processes, stores and discloses when this data is provided to us or collected as described under the “What personal data does Plusgrade process?” paragraph.</p>
                    </li>
                  </ol>
                }
              />
              {/** Question # 4 */}
              <QuestionSection 
                question = {"What are the legal basis and purposes for which Plusgrade processes personal data?"}
                answer={
                  <div>
                    <p>
                    We use travelers’ information to provide the Services to the travel industry companies we work with (i.e., Plusgrade’s “business partners”). We will also use this information to help improve product features or provide service enhancements and support our business partners in their current program and ongoing growth pursuant to our service contract. The use of an individual's personal data will be limited to the purposes for which it was collected by our business partners - to provide the booking upgrades and related services - as agreed to in our service agreement with our business partners. It is the responsibility of our business partners to ensure that they lawfully obtain personal data from travelers. As Plusgrade provides the technology upon which our business partners provide services to travelers, when travelers wish to change their options regarding their personal data, Plusgrade will process such wishes on behalf of our business partners and pursuant to their instructions. We use our business partners’, prospects’ and Site visitors’ personal data as necessary to respond to inquiries regarding our services, fulfil the relevant contract, send promotional emails and other communications (so long as you agree to receiving these communications) and as necessary for our legitimate interests in the processing. Where required by applicable law, we obtain your consent for the collection, use and disclosure of your personal information for the purposes set out in this Privacy Policy.</p>
                  </div>
                }

                learnMoreContent={
                  <div>
                    <ol>
                      <li>
                        Personal Data About Travelers:
                        <p>
                        <strong>To provide the Services to you and respond to your requests.</strong> When you ask for information about the Services (for example, when you request a demo or ask us to send you offers or price information), or register to a webinar or an event, we will use your contact information to respond to your request. For EU data subjects, such use is necessary to respond to or implement your request.

We use account-related data provided by our business partners in connection with the purchase, sign-up, use or support of the business partner account (such as usernames, email address and billing information) to provide you with access to the Services and/or the Site, contact you regarding your use of the Services and/or the Site or to notify you of important changes to the Services and/or the Site. For EU data subjects, such use is necessary for the performance of the contract between you and us.
</p>

<p><strong>For marketing purposes.</strong> We will use your email address to send you information about new products and services, upcoming events or other promotions. You may opt-out of receiving such emails by following the instructions contained in each promotional email we send you. Our sales representatives may also use your phone number to contact you directly by phone, in connection with such new products and services, upcoming events or other promotions.</p>

<p>Where required by applicable law (for example, if you are an EU data subject or a Canadian resident), we will only send you marketing information by email or mail, or contact you by phone, if you consent to us doing so at the time you provide us with your personal data (in such case, our legal basis for the processing of your Personal Data will be your consent). When you provide us with your consent to be contacted for marketing purposes, you have the right to withdraw your consent at any time by following the instructions to “opt-out” of receiving marketing communication in each marketing email we send you. In addition, if at any time you do not wish to receive future marketing communications or wish to have your name deleted from our mailing or calling lists, please contact us at
<a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>. </p>

<p>Please note that if you opt out from marketing communications, we may still contact you regarding issues related to our Services and to respond to your requests.
</p>

<p><strong>To analyze, administer, support, improve use of the Site and the Services.</strong>We use data relating to your use of and interaction with the Site and the Services, including information that we obtain through cookies, to analyze, administer, support and improve your access to and use of the Site and the Services. We may also compile, anonymize and/or aggregate your Personal Data and other data and use such anonymized and/or aggregated data for our business purposes, including sharing it with affiliates and business partners. This aggregate information does not identify you. </p>
<p>For EU data subjects, this use of your Personal Data is necessary for our legitimate interests in understanding how the Site and our Services are being used by you and to improve your experience on it. You have a right to object to the processing of your personal data based on our legitimate interest, as described under the “What privacy rights do individuals have regarding their own personal data?” section below.</p>
                      
                      <p>Please see the “What privacy rights do individuals have regarding their own personal data?” section below for information on the rights you may have in relation to the personal data we hold about you.</p>
                      </li>
                    </ol>
                  </div>
                }
              />
              {/** Question # 5 */}
              <QuestionSection 
                question = {"How long does Plusgrade retain personal data?"}
                answer={
                  <div>
                    <p>
                    Plusgrade retains travelers’ personal data for the duration of our service agreement with our business partners, unless a longer or shorter retention period is requested, required or permitted by applicable laws.
                    </p>
                    <p>
                    We keep the other personal data subject to this Privacy Policy only for as long as is reasonably necessary for the purposes described in this Policy, or for the duration required by law, whichever is the longer.
                    </p>
                  </div>
                }
              />
              {/** Question # 6 */}
              <QuestionSection 
                question = {"To which countries does Plusgrade transfer personal data as part of offering its services?"}
                answer={
                  <div>
                    <p>
                      As part of our service offering and to meet our contractual obligations, we transfer personal data from the EU to Canada, the United States, and travel industry companies’ (i.e., Plusgrade’s “business partners”) residential countries. We may also subcontract processing to, or share, travelers’ personal data with third parties located in countries other than their home country. As a result, your personal information may be transferred to a jurisdiction other than the jurisdiction in which we are located and in which you reside. In those circumstances, your personal information may be subject to the law of this foreign jurisdiction, and may thereby become accessible to government agencies, courts and law enforcement in that jurisdiction.
                    </p>
                    <p>
                    Subject to the foregoing, we endeavour to protect this information during cross-border transfers and comply with rules around cross-border data transfer in in the following ways:
                    </p>
                    <ul>
                      <li>
                        The European Commission has found that Canada offers an adequate level of protection for personal data transferred from the EU. This means that personal data can lawfully flow from the EU to Plusgrade in Canada without being subject to transfer rules;
                      </li>
                      <li>
                        To comply with restrictions on EU-US data transfers, we use “standard contractual clauses” (also known as "model clauses") approved by the European Commission.
                      </li>
                    </ul>
                    <p>
                      If you wish to obtain more information regarding Plusgrade’s safeguards used for international transfers of personal data, please contact us as indicated under the “How to contact Plusgrade?” section below.
                    </p>
                  </div>
                }
              />
              {/** Question # 7 */}
              <QuestionSection 
                question = {"Does Plusgrade share personal data with third parties?"}
                answer={
                  <div>
                    <p>
                    Plusgrade shares travelers’ personal data to third parties only as necessary to fulfil our service contract with travel industry companies we work with (i.e., Plusgrade’s “business partners”) and pursuant to our business partners’ instructions, if we sell or transfer any part of our business, or otherwise required by the applicable law.
                    </p>
                    <p>
                      Plusgrade shares our business partners’, prospects’ and Site Visitors’ personal data with third parties who support our business operations or as required or permitted by the applicable law.
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                      Plusgrade may share personal data with third parties in circumstances described below:
                      <ul>
                        <li>
                          Third Party Service Providers: in order to provide our business partners with certain services, Plusgrade may share personal data with third parties, for example: to provide information technology services; to conduct fraud protection services; to handle credit card transactions; to provide client service; or to perform marketing services. The service providers are only given the information needed to perform those services and they are bound contractually to protect the privacy and security of the information we provide and to limit the use of the information to the services being provided.
                        </li>
                        <li>
                          Governmental or Other Authorities: Plusgrade may, without notice to business partners, disclose personal data if required to do so by law, court order, as requested by other government or law enforcement authority, or in the good faith belief that disclosure is otherwise necessary or advisable including, without limitation:
                          <ul>
                            <li>
                              To protect the rights or properties of Plusgrade or its business partners;
                            </li>
                            <li>
                              When we have reason to believe that disclosing the information is necessary to identify, contact or bring legal action against someone who may be causing interference with our rights or properties;
                            </li>
                            <li>
                              When anyone else could be harmed by such activities.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Business Transfers: if Plusgrade or substantially all of its assets are acquired, personal data residing in our databases will most likely also be transferred in connection with such acquisition. In the case of any other significant transaction or event such as a merger, amalgamation, financing, re-organization, liquidation, or insolvency, other parties to the transaction and their professional advisors may need to have access to the Plusgrade databases as part of the due diligence process. In this event, Plusgrade would only provide such access under terms of a confidentiality agreement. Under completion of the transaction or event, personal data would be transferred to the successor-in-interest company and would be used for the purposes for which it was collected.
                        </li>
                      </ul>
                    </p>
                  </div>
                }
              />
              {/** Question # 8 */}
              <QuestionSection 
                question = {"What privacy rights do individuals have regarding their own personal data?"}
                answer={
                  <div>
                    <p>
                    Depending on where you reside, you may have some or all of the rights with respect to the processing of your personal data as set out below. Data subjects in the European Union (EU) have all of these rights (for these purposes, reference to the EU also includes the European Economic Area countries of Iceland, Liechtenstein and Norway and, where applicable, Switzerland). Data subjects in Canada have the right to request access to and/or correction of their own personal data, withdraw consent for the use of their personal data (except in limited circumstances, including legal or regulatory requirements or as a result of your contractual obligations with us), the right to lodge a complaint with the data protection authority, and, in Quebec, the right to request erasure where the personal data is obsolete or no longer required for the purposes for which it was collected.</p>
                    <ol>
                      <li>
                        Travelers’ Rights:
                        <p>
                        Travelers have the right to submit the following requests regarding their personal data to the travel industry companies (i.e., Plusgrade’s “business partners”) to whom travelers have previously provided their personal data, subject to applicable law:</p>
                        <ul>
                          <li>
                          Request access to and/or correction of their own personal data
                          </li>
                          <li>
                            Request the erasure of their own personal data           
                          </li>
                          <li>
                          Request that Plusgrade’s business partners stop processing or restrict the processing of their own personal data</li>
                          <li>
                          Request that Plusgrade’s business partners provide them with their personal data in a structured, commonly used and machine-readable format and transmit those data to another organization</li>
                        </ul>
                        <p>
                        Upon notification from our business partners of a traveler’s request to exercise their rights, we will enable such requests to ensure our business partners’ compliance with applicable regulatory requirements in accordance with the service contract with the applicable business partner.</p>
                      </li>

                      <li>
                        Business Partners’, Prospects’, Site Visitors’ Rights:
                        <p>
                          Subject to applicable law, you have the following rights in relation to your personal data:
                        </p>
                        <ul>
                          <li>
                            <strong>Right of access:</strong> If you ask us, we will confirm whether we are processing your personal data and, if so, provide you with a copy of that personal data along with certain other details. If you require additional copies, we may need to charge a reasonable fee.
                          </li>
                          <li>
                            <strong>Right to rectification:</strong> If your personal data is inaccurate or incomplete, you are entitled to ask that we correct or complete it. If we shared your personal data with others, we will tell them about the correction where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data so you can contact them directly.
                          </li>
                          <li>
                          <strong>Right to erasure:</strong> You may ask us to delete or remove your personal data, such as where you withdraw your consent. If we shared your data with others, we will tell them about the erasure where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data with so you can contact them directly.
                          </li>
                          <li>
                          <strong>Right to restrict processing:</strong> You may ask us to restrict or ‘block’ the processing of your personal data in certain circumstances, such as where you contest the accuracy of the data or object to us processing it. We will tell you before we lift any restriction on processing. If we shared your personal data with others, we will tell them about the restriction where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data so you can contact them directly.
                          </li>
                          <li>
                          <strong>Right to data portability:</strong> You have the right to obtain your personal data from us that you consented to give us or that was provided to us as necessary in connection with our contract with you. We will give you your personal data in a structured, commonly used and machine-readable format. You may reuse it elsewhere.
                          </li>
                          <li>
                          <strong>Right to object:</strong> You may ask us at any time to stop processing your personal data, and we will do so if we are relying on a legitimate interest to process your personal data (unless we demonstrate compelling legitimate grounds for the processing) or if we are processing your personal data for direct marketing.
                          </li>
                          <li>
                          <strong>Right to withdraw consent:</strong> If we rely on your consent to process your personal data, you have the right to withdraw that consent at any time. This will not affect the lawfulness of processing of your data before we received notice that you wished to withdraw your consent.
                          </li>
                          <li>
                          <strong>Right to lodge a complaint with the data protection authority:</strong> If you have a concern about our privacy practices, including the way we handled your personal data, you can report it to the data protection authority that is authorized to hear those concerns.
                          </li>
                        </ul>
                      </li>
                    </ol>
                   
                  </div>
                }
              />
              {/** Question # 9 */}
              <QuestionSection 
                question = {"How does Plusgrade protect personal data?"}
                answer={
                  <div>
                    <p>Plusgrade will take reasonable precautions, including the implementation of appropriate physical, electronic and administrative procedures, to protect personal data from loss, misuse and unauthorized access, disclosure, alteration and destruction. Although we employ technical safeguards, we cannot guarantee the security of personal data on or transmitted via the Internet.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    We have implemented strong security measures as part of our services to the travel and hospitality companies we work with (i.e., Plusgrade’s “business partners”). We maintain policies and practices to protect your information that provide a framework for our handling of your personal information, define roles and responsibilities for handling your information from the moment we collect it until it is destroyed, and govern our internal process for dealing with complaints regarding the protection of personal data, including our Data Protection Policy, Internet and Network Security Policy, and Incident Reporting Policy. In particular, these policies govern the measures we take to protect company resources and assets, including data, application architecture, application code, network access, location access, and hardware/device accessand ensure timely availability and access to personal data in the event of a physical or technical incident, as well as implemented internal processes for regularly testing, assessing, and evaluating the effectiveness of the security measures.</p>
                  </div>
                }
              />
              {/** Question # 10 */}
              <QuestionSection 
                question = {"How is this privacy policy enforced at Plusgrade?"}
                answer={
                  <div>
                    <p>
                    Plusgrade follows an internal compliance monitoring process, to assure compliance with this Privacy Policy and periodically verifies that it is accurate, comprehensive for the information intended to be covered, prominently displayed, completely implemented and accessible, and in conformity with privacy and data protection legislation applicable to Plusgrade.</p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                    We will review the information in this Privacy Policy on an ongoing basis and make sure that it remains relevant to the organization practices and compliant with the upcoming privacy and data protection regulatory requirements. Occasionally, we may also, in our discretion, make changes to this Privacy Policy.</p>

                    <p>
                    When we do so, we will post a new version of the Privacy Policy reflecting these changes on the Site. If we make material changes to this Privacy Policy, we will provide individuals with a prominent notice as appropriate under the circumstances and pursuant to the applicable law. These notices may be under the form of an email or a prominent notice on our website. The continued use of our Site or services after that notice will constitute acceptance of the changes. If you do not agree to the changes in our Privacy Policy, it is your responsibility to stop using the Site and our services. The "Effective Date" at the top of this Privacy Policy indicates when it was last updated.</p>
                  </div>
                }
              />
              {/** Question # 11 */}
              <QuestionSection 
                question = {"How to contact Plusgrade?"}
                answer={
                  <div>
                    <p>
                      Plusgrade acts as the data processor for the processing of travelers’ personal data on behalf of travel industry companies (i.e., Plusgrade’s “business partners”), pursuant to our service contract with the applicable business partner.
                    </p>
                    <p>
                      We act as the data controller for the processing of personal data about our business partners, prospects and Site visitors for the purposes described in this Privacy Policy.
                    </p>
                    <p>
                      Questions, comments or complaints regarding our Privacy Policy or data processing practices can be mailed or emailed directly to Plusgrade’s Data Protection Officer:
                    </p>
                    <a href="mailto:dpo@plusgrade.com">dpo@plusgrade.com</a>
                    <p>
                      Questions, comments or complaints regarding our Privacy Policy or data collection and processing practices concerning EU data subjects can be mailed or emailed to Plusgrade’s EU based Representative:
                    </p>
                  </div>
                }
                learnMoreContent={
                  <div>
                    <p>
                      If you feel you haven’t received a timely or satisfactory response from us to your question or complaint, please contact the applicable data protection authority below:
                    </p>
                    <a href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">EU Data Protection Authorities (DPAs)</a>
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb.html">Swiss Federal Data Protection and Information Commissioner (FDPIC)</a>
                    <a href="https://www.priv.gc.ca/en">Office of the Privacy Commissioner of Canada</a>
                  </div>
                }
              />
            </div>
          </Copy>
        </PrivacyPolicy>
      </Content>
  </Layout>
  );
  
}

export default PrivacyPage;

const QuestionSectionContainer = styled.div`
font-family: ${font.family.sherika};
pointer-events: none;
p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 10px;
  font-size: 24px;
  font-weight: 700;
  color: ${brand.colors.pg_blue};
  margin-bottom: 10px;
  pointer-events: all;
  cursor: pointer;
  border-bottom: 1px solid #BDC4D1;
  padding-bottom: 10px;

  .expand-icon {
    color: #BDC4D1;
    font-size: 40px;
  }
}

.reveal-container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  border: none;
  width: 95%;
  margin: 0 auto 20px;
   p {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${brand.colors.pg_primary_dark};
    margin: 10px 0;
  }

  .italized {
    font-style: italic;
    font-size: 20px;
  }

  a {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
    pointer-events: all;
  }

  ol {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_blue};
      list-style: none;
      font-weight: 800;
      margin: 10px 0;
      font-size: 20px;

      ul > li {
        color: ${brand.colors.pg_primary_dark};
        font-weight: 400;
        font-size: 20px;
      }

      ul > li:before {
        content: '';
      }
    }

    li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
      font-size: 20px;
    }
  }
  ul {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_primary_dark};
      list-style: circle;
    }

    ul > li {
      list-style: square;
    }
  }
}

${props => props.open === true && css`
  p {
    border: none;
  }
  .reveal-container {
    max-height: 6000px;
  }
`}

`;

const LearnMoreSection = styled.div`
  .learn-more-clickable {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
  }
  .learn-more-expandable {
    color: ${brand.colors.pg_primary_dark};
    height: auto;
    max-height: 0;
    width: 95%;
    margin: auto;

    a {
      display: block;
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;
      color: ${brand.colors.pg_blue};
    }
  }

  ${props => props.open === true && css`
    .learn-more-expandable {
      max-height: 6000px;
    }
  `}
`;



{/**
Question Section Component
Accepts
- Question prop = Clickable Header section question
- Answer Prop = Content of dropdown, accepts HTML. Check styled component here carefully as adding p, ul, or ol are pre-styled.
- learnMoreContent = Dropdown content of the 'learn more' section
*/}
const QuestionSection = ({question, answer, learnMoreContent}) => {

const [FAQOpen, setFAQSection] = useState(false);
const [learnMoreOpen, setLearnMore] = useState(false); 

  const pageQuery = useStaticQuery(graphql`
  query{
    plusIconGrey: file(base: {eq: "plus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    minusIconGrey: file(base: {eq: "minus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`);

let plusIcon = <span className={"expand-icon"}>+</span>
let minusIcon = <span className={"expand-icon"}>-</span>
const openClose = () => {
  if (FAQOpen && learnMoreOpen){
    setLearnMore(false);
  }
  setFAQSection(!FAQOpen); 
}

const expandCompressLearnMore = () => {
  setLearnMore(!learnMoreOpen);
}
  return(
    <QuestionSectionContainer open={FAQOpen}>
      <p onClick={openClose}>{question} {FAQOpen ? minusIcon : plusIcon}</p>
      <div className={"reveal-container"}>
        {answer}
      {
        learnMoreContent ? 
        <LearnMoreSection open = {learnMoreOpen}>
          <p className={"learn-more-clickable"} onClick={expandCompressLearnMore}>Learn More</p>
          <div className={"learn-more-expandable"}>
            {learnMoreContent}
          </div>
      </LearnMoreSection>
      :
      <></>
      }
      </div>
    </QuestionSectionContainer>
  );
}
